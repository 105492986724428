import { json } from '@remix-run/node'
import type { LoaderFunction, MetaFunction } from '@remix-run/node'
import { Link, useLoaderData } from "@remix-run/react"
import { ShopCard } from '~/components/cards'
import { ProductCard as BicycleCard } from '~/components/bicycle'
import { ProductCard as PartCard } from '~/components/part'
import { ProductCard as SupplyCard } from '~/components/supply'
import path from '~/constants/path'
import { getAppAssetsBaseURL } from '~/utils/application.server'
import { createMeta } from '~/utils/application'
import { ProductRepository } from '~/repositories/user/product.server'
import { ShopUseCase } from '~/usecases/shop.server'
import {
  BicycleGenreModel,
  ProductModelJSON,
  ShopModelJSON,
} from '~/models'

import { keys } from './_user.search.$keywords'

type LoaderData = {
  assetsBaseURL: string
  bicycles: ProductModelJSON[]
  parts: ProductModelJSON[]
  supplies: ProductModelJSON[]
  pickups: ProductModelJSON[]
  shops: ShopModelJSON[]
}

export const loader: LoaderFunction = async () => {
  const repository = new ProductRepository()
  const bicycles = await repository.findMany({ type: 0, take: 8 })
  const parts = await repository.findMany({ type: 1, take: 8 })
  const supplies = await repository.findMany({ type: 2, take: 8 })
  const pickups = await repository.findMany({
    type: 2,
    bicycle: { genre: BicycleGenreModel.getNumber('グラベル') },
    take: 30,
  })
  const shopUseCase = new ShopUseCase()
  const shops = await shopUseCase.findLatest({ take: 8 })
  return json<LoaderData>({
    assetsBaseURL: getAppAssetsBaseURL(),
    bicycles: bicycles,
    parts: parts,
    supplies: supplies,
    pickups: pickups.length > 4 ? pickups.sort(_ => Math.random() - .5).slice(0, 4) : pickups,
    shops: shops,
  })
}

export const meta: MetaFunction = args => createMeta({
  ...args,
  title: "あなたが探している自転車が見つかるポータルサイト！",
})

export default function App() {
  const data = useLoaderData<LoaderData>()
  if (!data) return null

  return (
    <>
      <p className="my-2 px-4 md:px-12 text-center text-xs">
        サイクルミッケは全国のサイクルショップが出店する自転車専用ポータルサイトです。希少品や掘り出し物、最新の在庫情報など、一堂に掲載されます。今まで探していたものが見つかるかも！<span className="italic">“探してみよう、サイクルミッケ！”</span>
      </p>
      <div>
        <div className="h-44 flex justify-center align-center">
          <Link
            className="h-full m-auto"
            to={path.user.requests.new}
          >
            <img
              src="/images/banner/bicycle_request.png"
              className="h-full w-auto object-contain"
            />
          </Link>
        </div>
      </div>
      <div className="my-6">
        <div className="my-2 flex justify-between align-bottom">
          <h2 className="border-l-4 border-primary pl-2 text-lg">注目のグラベル</h2>
          <Link
            to={`/search?${keys.type}=0&${keys.genre}=${BicycleGenreModel.getNumber('グラベル')}`}
            className="text-sm italic underline cursor-pointer"
          >もっと見る</Link>
        </div>
        <div className="flex flex-row flex-wrap w-auto my-2">
          {data && data.pickups.map((product, index) => (
            <div
              key={product.displayID}
              className={`p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0 ${index > 1 ? 'hidden' : ''} ${index > 1 ? 'sm:block' : ''} ${index > 2 ? 'md:block' : ''}`}
            >
              <BicycleCard
                key={product.displayID}
                assetsBaseURL={data.assetsBaseURL}
                product={product}
              />
            </div>
          ))}
        </div>
        <div className="h-44 flex justify-center align-center">
          <div className="h-44 flex justify-center align-center bg-gray-100 relative z-0">
            <Link
              to={`/search?${keys.type}=0&${keys.genre}=${BicycleGenreModel.getNumber('グラベル')}`}
              className="h-full m-auto"
            >
              <img
                src="/images/banner/pickup.png"
                className="h-full w-auto object-contain"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="my-6">
        <div className="my-2 flex justify-between align-bottom">
          <h2 className="border-l-4 border-primary pl-2 text-lg">掲載の自転車</h2>
          <Link to={`/search?${keys.type}=0`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
        <div className="flex flex-row flex-wrap w-auto my-2">
          {data && data.bicycles.map(product => (
            <div
              key={product.displayID}
              className="p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0"
            >
              <BicycleCard
                key={product.displayID}
                assetsBaseURL={data.assetsBaseURL}
                product={product}
              />
            </div>
          ))}
        </div>
        <div className="my-2 flex justify-end align-bottom">
          <Link to={`/search?${keys.type}=0`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
      </div>
      <div className="my-6">
        <div className="my-2 flex justify-between align-bottom">
          <h2 className="border-l-4 border-primary pl-2 text-lg">掲載のパーツ</h2>
          <Link to={`/search?${keys.type}=1`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
        <div className="flex flex-row flex-wrap w-auto my-2">
          {data && data.parts.map(product => (
            <div
              key={product.displayID}
              className="p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0"
            >
              <PartCard
                key={product.displayID}
                assetsBaseURL={data.assetsBaseURL}
                product={product}
              />
            </div>
          ))}
        </div>
        <div className="my-2 flex justify-end align-bottom">
          <Link to={`/search?${keys.type}=1`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
      </div>
      <div className="my-6">
        <div className="my-2 flex justify-between align-bottom">
          <h2 className="border-l-4 border-primary pl-2 text-lg">掲載の用品</h2>
          <Link to={`/search?${keys.type}=2`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
        <div className="flex flex-row flex-wrap w-auto my-2">
          {data && data.supplies.map(product => (
            <div
              key={product.displayID}
              className="p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0"
            >
              <SupplyCard
                key={product.displayID}
                assetsBaseURL={data.assetsBaseURL}
                product={product}
              />
            </div>
          ))}
        </div>
        <div className="my-2 flex justify-end align-bottom">
          <Link to={`/search?${keys.type}=2`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
      </div>
      <div className="my-6">
        <div className="my-2 flex justify-between align-bottom">
          <h2 className="border-l-4 border-primary pl-2 text-lg">掲載のショップ</h2>
          <Link to={`${path.user.shops.home}/`} className="text-sm italic underline cursor-pointer">もっと見る</Link>
        </div>
        <div className="flex flex-row flex-wrap w-auto my-2">
          {data && data.shops.map(shop => (
            <div
              key={shop.displayID}
              className="p-2 basis-1/2 sm:basis-1/3 md:basis-1/4 flex justify-center min-w-0"
            >
              <ShopCard
                key={shop.displayID}
                assetsBaseURL={data.assetsBaseURL}
                shop={shop}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="h-44 flex justify-center align-center">
        <div className="h-44 flex justify-center align-center bg-gray-100">
          <a
            className="h-full m-auto"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLScC52fRbXP5ptu9NrG8xdXPWDErEPt5xu08blSmusqJO6kdxw/viewform?usp=sf_link"
          >
            <img
              src="/images/banner/cyclemikke_1.png"
              className="h-full w-auto object-contain"
            />
          </a>
        </div>
      </div>
    </>
  )
}
